@font-face {
	font-family: "comp-iconfont";
	src: url('../new-fonts/comp/comp-iconfont.eot');
	src: url('../new-fonts/comp/comp-iconfont.eot?#iefix') format('eot'),
		url('../new-fonts/comp/comp-iconfont.woff2') format('woff2'),
		url('../new-fonts/comp/comp-iconfont.woff') format('woff'),
		url('../new-fonts/comp/comp-iconfont.ttf') format('truetype'),
		url('../new-fonts/comp/comp-iconfont.svg#comp-iconfont') format('svg');
}

.icon:before {
	font-family: "comp-iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-002-planning:before {
	content: "\E001";
}

.icon-Vector_bg:before {
	content: "\E002";
}

.icon-analysis-2:before {
	content: "\E003";
}

.icon-analysis-yellow:before {
	content: "\E004";
}

.icon-analysis:before {
	content: "\E005";
}

.icon-arrow-left:before {
	content: "\E006";
}

.icon-arrow-right:before {
	content: "\E007";
}

.icon-arrow:before {
	content: "\E008";
}

.icon-bag:before {
	content: "\E009";
}

.icon-bg:before {
	content: "\E00A";
}

.icon-big-arrow:before {
	content: "\E00B";
}

.icon-brain:before {
	content: "\E00C";
}

.icon-buy:before {
	content: "\E00D";
}

.icon-case:before {
	content: "\E00E";
}

.icon-chair:before {
	content: "\E00F";
}

.icon-completo:before {
	content: "\E010";
}

.icon-computer:before {
	content: "\E011";
}

.icon-cpu:before {
	content: "\E012";
}

.icon-crown:before {
	content: "\E013";
}

.icon-cursor-2:before {
	content: "\E014";
}

.icon-cursor:before {
	content: "\E015";
}

.icon-customer-service:before {
	content: "\E016";
}

.icon-desktop:before {
	content: "\E017";
}

.icon-digital-marketing:before {
	content: "\E018";
}

.icon-eye:before {
	content: "\E019";
}

.icon-facebook-logo:before {
	content: "\E01A";
}

.icon-fb-svg:before {
	content: "\E01B";
}

.icon-feature:before {
	content: "\E01C";
}

.icon-fingerprint:before {
	content: "\E01D";
}

.icon-fire:before {
	content: "\E01E";
}

.icon-franchise:before {
	content: "\E01F";
}

.icon-global:before {
	content: "\E020";
}

.icon-graphic:before {
	content: "\E021";
}

.icon-growth-yellow:before {
	content: "\E022";
}

.icon-growth:before {
	content: "\E023";
}

.icon-hand:before {
	content: "\E024";
}

.icon-hourglass-2:before {
	content: "\E025";
}

.icon-hourglass:before {
	content: "\E026";
}

.icon-i:before {
	content: "\E027";
}

.icon-instruction-2:before {
	content: "\E028";
}

.icon-instruction:before {
	content: "\E029";
}

.icon-internet-2:before {
	content: "\E02A";
}

.icon-internet:before {
	content: "\E02B";
}

.icon-laminat:before {
	content: "\E02C";
}

.icon-like:before {
	content: "\E02D";
}

.icon-m:before {
	content: "\E02E";
}

.icon-market-3:before {
	content: "\E02F";
}

.icon-market:before {
	content: "\E030";
}

.icon-medal:before {
	content: "\E031";
}

.icon-metal:before {
	content: "\E032";
}

.icon-noise:before {
	content: "\E033";
}

.icon-offer:before {
	content: "\E034";
}

.icon-outsourcing:before {
	content: "\E035";
}

.icon-planning-2:before {
	content: "\E036";
}

.icon-planning:before {
	content: "\E037";
}

.icon-play:before {
	content: "\E038";
}

.icon-purse:before {
	content: "\E039";
}

.icon-question:before {
	content: "\E03A";
}

.icon-robot-arm:before {
	content: "\E03B";
}

.icon-route:before {
	content: "\E03C";
}

.icon-setting:before {
	content: "\E03D";
}

.icon-shop:before {
	content: "\E03E";
}

.icon-steel:before {
	content: "\E03F";
}

.icon-thermo:before {
	content: "\E040";
}

.icon-trolley:before {
	content: "\E041";
}

.icon-upload:before {
	content: "\E042";
}

.icon-vector-bold:before {
	content: "\E043";
}

.icon-vector-button:before {
	content: "\E044";
}

.icon-vector-yellow:before {
	content: "\E045";
}

.icon-video:before {
	content: "\E046";
}

.icon-vision:before {
	content: "\E047";
}

.icon-vk-social-network-logo:before {
	content: "\E048";
}

.icon-warning:before {
	content: "\E049";
}

.icon-website-2:before {
	content: "\E04A";
}

.icon-website:before {
	content: "\E04B";
}

.icon-win:before {
	content: "\E04C";
}

.icon-x4:before {
	content: "\E04D";
}

.icon-x9:before {
	content: "\E04E";
}

.icon-yellow_quotes:before {
	content: "\E04F";
}

.icon-youtube-logo:before {
	content: "\E050";
}
