.book {
	&__container {
		background-image: url('picture/backgrounds/book-bg.svg');
		background-size: 625px;
		background-position: left top;
		background-repeat: no-repeat;
		padding-top: 100px;
		padding-bottom: 70px;
		@include media-breakpoint-up(xl) {
			background-size: 50%;
		}
		@include media-breakpoint-down(sm) {
			background-position: left bottom;
		}
	}
	&__main-img {
		width: 100%;
		max-width: 400px;
	}
	&-header .header {
		background-image: url('picture/backgrounds/topographic-transparent-02.png');
    background-repeat: repeat;
    box-shadow: 0 5px 5px rgba(0,0,0,.25);
	}
	&__text {
		background-color: white;
		width: 310px;
		padding: 9px 0 9px 20px;
		display: flex;
		align-items: center;
		//position: absolute;
		//right: 0;
		margin-top: 40px;
		//bottom: 100px;
		box-shadow: 7px -4px 50px rgba(0, 0, 0, 0.1);
		@include media-breakpoint-down(md){
			/*right: auto;
			left: 0;*/
			width: 100%;
			/*position: relative;
			bottom: 70px;*/
		}
		p {
			font-size: 12px;
			margin-bottom: 0;
			width: calc(100% - 100px);
			color: #283869;
		}
		img {
			width: 50px;
			margin: 0 10px;
			display: block;
		}
	}
	&__btn {
		display: inline-block;
		font-size: 14px;
		transition: 0.2s ease all;
		text-decoration: none !important;
		padding: 10px 0;
		width: 167px;
		text-align: center;
		&-dark {
			background-color: transparent;
			border: 2px solid #060606;
			color: #050505;
			&:hover {
				background-color: #050505;
				color: white;
			}
		}
		&-warning {
			background-color: $yellow;
			border: 2px solid $yellow;
			color: #050505;
			&:hover {
				background-color: transparent;
				color: $yellow;
			}
		}
	}
	&__link {
		text-decoration: none !important;
		&-under {
			width: 167px;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 0.6;
		}
	}
	&__col {
		margin-left: 65px;
		@include media-breakpoint-down(md) {
			margin-left: 0;
		}
	}
	&__form {
		background-color: #F5F5F5;
		box-shadow: none;
		max-width: 422px;
		@include media-breakpoint-down(sm) {
			max-width: inherit;
		}
	}
	&__label {
		color: rgba(0,0,0,0.5);
		display: block;
		margin-bottom: 15px;
	}
	&__input {
		border: 1px solid rgba(0, 0, 0, 0.45);
		height: 48px;
		background-color: transparent;
		width: 100%;
		padding: 10px 20px;
		outline: none;
		transition: 0.2s ease all;
		&:hover,
		&:focus,
		&:active {
			border: 1px solid $yellow;
			background-color: white;
		}
	}
	&__title {
		font-size: 36px;
	}
	&__main-title {
		font-size: 3.3rem;
		margin-bottom: 1.4rem;
		@include media-breakpoint-down(md) {
			font-size: 2.5rem;
		}
		@include media-breakpoint-down(sm) {
			font-size: 2.125rem;
		}
	}
	&__form-under {
		display: flex;
		align-items: center;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			button {
				width: 100%;
			}
		}
	}
	&__politic {
		font-size: 10px;
		color: rgba(0,0,0,.5);
		margin-left: 10px;
		width: calc(100% - 177px);
		@include media-breakpoint-down(sm) {
			width: 100%;
			margin-left: 0;
			margin-top: 15px;
		}
	}
	&__order-1 {
		@include media-breakpoint-down(sm) {
			order: 2;
		}
		
	}
	&__order-2 {
		@include media-breakpoint-down(sm) {
			order: 1;
		}
	}
}
.yellow-border {
	position: relative;
	.border-container {
		left: 0;
    bottom: 7px;
    position: absolute;
    height: 14px;
    background-color: #FECC2F;
    z-index: -1;
    width: 0;
	}
}
.newline {
	animation-name: newline;
	animation-timing-function: linear;
}
@keyframes newline {
  from {
    width: 0px; }
  to {
		width: calc(100% + 7px); } 
}
.book-footer button.data-scroll {
	display: none;
}