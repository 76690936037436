.book {
	&__col-1 {
		max-width: 230px;
		@include media-breakpoint-down(md) {
			max-width: 260px;
		}
		@include media-breakpoint-down(sm) { 
			max-width: inherit;
			flex-basis: auto;
		}
	}
	&__new {
		background-color: $yellow;
		background-image: url('picture/upload/dots.png');
		background-size: 230px;
		background-repeat: repeat-y;
		background-position: top right;
		&-img {
			width: 100%;
			@include media-breakpoint-down(sm) {
				display: block;
				margin: auto;
				max-width: 320px;
			}
		}
		&-title {
			@include media-breakpoint-down(lg) {
				font-size: 2.7rem;
			}
			@include media-breakpoint-down(sm) {
				font-size: 34px;
			}
		}
		&-text {
			margin-bottom: 30px;
			@include media-breakpoint-down(md) {
				margin-bottom: 0;
			}
			@include media-breakpoint-down(sm) {
				margin-bottom: 30px;
				width: 100%;
				flex-basis: auto;
				padding-top: 30px;
				background-color: $yellow;
			}
		}
	}
}