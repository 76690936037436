/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-Runet-2x {
  background-image: url(/picture/sprites/icons-sprite.png);
  background-position: 0px -177px;
  width: 156px;
  height: 196px;
}
.icon-Runet {
  background-image: url(/picture/sprites/icons-sprite.png);
  background-position: -225px 0px;
  width: 78px;
  height: 98px;
}
.icon-Ruward-2x {
  background-image: url(/picture/sprites/icons-sprite.png);
  background-position: 0px 0px;
  width: 224px;
  height: 176px;
}
.icon-Ruward {
  background-image: url(/picture/sprites/icons-sprite.png);
  background-position: -225px -99px;
  width: 56px;
  height: 44px;
}
