.book {
	&__writers {
		padding: 70px 0;
		overflow: hidden;
		@include media-breakpoint-down(sm) {
			padding: 30px 0;
		}
		&-scrollbar {
			min-width: 800px;
			width: 100%;
		}
	}
	&__big-text {
		color: #F8F8F8;
		font-size: 122px;
		font-weight: bold;
		position: absolute;
		top: -66px;
		z-index: -1;
		left: 15px;
		white-space: nowrap;
		visibility: hidden;
		@include media-breakpoint-down(sm) {
			font-size: 55px;
			top: -29px;
		}
	}
}
.writer {
	width: 260px;
	position: relative;
	margin-top: 40px;
	&__container {
		@include media-breakpoint-down(md) {
			min-width: 260px;
		}
	}
	&__avatar {
		img {
			max-width: 130px;
			width: 100%;
			margin-left: 60px;
		}
	}
	&__main {
		font-size: 12px;
		color: rgba(0,0,0,0.5);
		margin-bottom: 20px;
	}
	&__circle {
		width: 165px;
		height: 165px;
		background-color: $yellow;
		position: absolute;
		left: 0;
		top: -35px;
		border-radius: 100%;
		z-index: -1;
	}
}