/* Generated by ParaType Ltd (http://www.paratype.com)*/
/* Font PT Serif:  Copyright &copy; 2010 ParaType Ltd. All rights reserved.*/
@import url(../style/fancybox/jquery.fancybox.css);
@font-face {
  font-family: 'PT Sans';
  font-display: fallback;
  src: url("../fonts/PT_Sans-Web-Regular.ttf") format("opentype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'PT Sans';
  font-display: fallback;
  src: url("../fonts/PT_Sans-Web-Italic.ttf") format("opentype");
  font-style: italic;
  font-weight: normal; }

@font-face {
  font-family: 'PT Sans';
  font-display: fallback;
  src: url("../fonts/PT_Sans-Web-Bold.ttf") format("opentype");
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: 'PT Sans';
  font-display: fallback;
  src: url("../fonts/PT_Sans-Web-BoldItalic.ttf") format("opentype");
  font-style: italic;
  font-weight: bold; }

/*
@font-face {
		font-family: 'PTSans';
		font-display: fallback;
    src: url('../fonts/pts75f_w.eot');
    font-style: normal;
    font-weight: bold;
    src: local('PT Sans'), url('../fonts/PTS75F_W.eot?#iefix') format('embedded-opentype'), url('../fonts/PTS75F_W.woff') format('woff'),url('../fonts/PTS75F_W.ttf') format('truetype'), url('../fonts/PTS75F_W.svg#PTSans-Bold') format('svg');
}*/
@font-face {
  font-family: "comp-iconfont";
  src: url("../new-fonts/comp/comp-iconfont.eot");
  src: url("../new-fonts/comp/comp-iconfont.eot?#iefix") format("eot"), url("../new-fonts/comp/comp-iconfont.woff2") format("woff2"), url("../new-fonts/comp/comp-iconfont.woff") format("woff"), url("../new-fonts/comp/comp-iconfont.ttf") format("truetype"), url("../new-fonts/comp/comp-iconfont.svg#comp-iconfont") format("svg"); }

.icon:before {
  font-family: "comp-iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none; }

.icon-002-planning:before {
  content: "\E001"; }

.icon-Vector_bg:before {
  content: "\E002"; }

.icon-analysis-2:before {
  content: "\E003"; }

.icon-analysis-yellow:before {
  content: "\E004"; }

.icon-analysis:before {
  content: "\E005"; }

.icon-arrow-left:before {
  content: "\E006"; }

.icon-arrow-right:before {
  content: "\E007"; }

.icon-arrow:before {
  content: "\E008"; }

.icon-bag:before {
  content: "\E009"; }

.icon-bg:before {
  content: "\E00A"; }

.icon-big-arrow:before {
  content: "\E00B"; }

.icon-brain:before {
  content: "\E00C"; }

.icon-buy:before {
  content: "\E00D"; }

.icon-case:before {
  content: "\E00E"; }

.icon-chair:before {
  content: "\E00F"; }

.icon-completo:before {
  content: "\E010"; }

.icon-computer:before {
  content: "\E011"; }

.icon-cpu:before {
  content: "\E012"; }

.icon-crown:before {
  content: "\E013"; }

.icon-cursor-2:before {
  content: "\E014"; }

.icon-cursor:before {
  content: "\E015"; }

.icon-customer-service:before {
  content: "\E016"; }

.icon-desktop:before {
  content: "\E017"; }

.icon-digital-marketing:before {
  content: "\E018"; }

.icon-eye:before {
  content: "\E019"; }

.icon-facebook-logo:before {
  content: "\E01A"; }

.icon-fb-svg:before {
  content: "\E01B"; }

.icon-feature:before {
  content: "\E01C"; }

.icon-fingerprint:before {
  content: "\E01D"; }

.icon-fire:before {
  content: "\E01E"; }

.icon-franchise:before {
  content: "\E01F"; }

.icon-global:before {
  content: "\E020"; }

.icon-graphic:before {
  content: "\E021"; }

.icon-growth-yellow:before {
  content: "\E022"; }

.icon-growth:before {
  content: "\E023"; }

.icon-hand:before {
  content: "\E024"; }

.icon-hourglass-2:before {
  content: "\E025"; }

.icon-hourglass:before {
  content: "\E026"; }

.icon-i:before {
  content: "\E027"; }

.icon-instruction-2:before {
  content: "\E028"; }

.icon-instruction:before {
  content: "\E029"; }

.icon-internet-2:before {
  content: "\E02A"; }

.icon-internet:before {
  content: "\E02B"; }

.icon-laminat:before {
  content: "\E02C"; }

.icon-like:before {
  content: "\E02D"; }

.icon-m:before {
  content: "\E02E"; }

.icon-market-3:before {
  content: "\E02F"; }

.icon-market:before {
  content: "\E030"; }

.icon-medal:before {
  content: "\E031"; }

.icon-metal:before {
  content: "\E032"; }

.icon-noise:before {
  content: "\E033"; }

.icon-offer:before {
  content: "\E034"; }

.icon-outsourcing:before {
  content: "\E035"; }

.icon-planning-2:before {
  content: "\E036"; }

.icon-planning:before {
  content: "\E037"; }

.icon-play:before {
  content: "\E038"; }

.icon-purse:before {
  content: "\E039"; }

.icon-question:before {
  content: "\E03A"; }

.icon-robot-arm:before {
  content: "\E03B"; }

.icon-route:before {
  content: "\E03C"; }

.icon-setting:before {
  content: "\E03D"; }

.icon-shop:before {
  content: "\E03E"; }

.icon-steel:before {
  content: "\E03F"; }

.icon-thermo:before {
  content: "\E040"; }

.icon-trolley:before {
  content: "\E041"; }

.icon-upload:before {
  content: "\E042"; }

.icon-vector-bold:before {
  content: "\E043"; }

.icon-vector-button:before {
  content: "\E044"; }

.icon-vector-yellow:before {
  content: "\E045"; }

.icon-video:before {
  content: "\E046"; }

.icon-vision:before {
  content: "\E047"; }

.icon-vk-social-network-logo:before {
  content: "\E048"; }

.icon-warning:before {
  content: "\E049"; }

.icon-website-2:before {
  content: "\E04A"; }

.icon-website:before {
  content: "\E04B"; }

.icon-win:before {
  content: "\E04C"; }

.icon-x4:before {
  content: "\E04D"; }

.icon-x9:before {
  content: "\E04E"; }

.icon-yellow_quotes:before {
  content: "\E04F"; }

.icon-youtube-logo:before {
  content: "\E050"; }

/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-Runet-2x {
  background-image: url(/picture/sprites/icons-sprite.png);
  background-position: 0px -177px;
  width: 156px;
  height: 196px; }

.icon-Runet {
  background-image: url(/picture/sprites/icons-sprite.png);
  background-position: -225px 0px;
  width: 78px;
  height: 98px; }

.icon-Ruward-2x {
  background-image: url(/picture/sprites/icons-sprite.png);
  background-position: 0px 0px;
  width: 224px;
  height: 176px; }

.icon-Ruward {
  background-image: url(/picture/sprites/icons-sprite.png);
  background-position: -225px -99px;
  width: 56px;
  height: 44px; }

:root {
  --blue: #0082F9;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #FECC2F;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #215B5F;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0082F9;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #215B5F;
  --warning: #FECC2F;
  --danger: #FF0000;
  --light: #F2F6FA;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1280px;
  --font-family-sans-serif: "PT Sans", sans-serif;
  --font-family-monospace: "PT Sans", sans-serif; }

.book__container {
  background-image: url("picture/backgrounds/book-bg.svg");
  background-size: 625px;
  background-position: left top;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 70px; }
  @media (min-width: 1280px) {
    .book__container {
      background-size: 50%; } }
  @media (max-width: 767.98px) {
    .book__container {
      background-position: left bottom; } }

.book__main-img {
  width: 100%;
  max-width: 400px; }

.book-header .header {
  background-image: url("picture/backgrounds/topographic-transparent-02.png");
  background-repeat: repeat;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25); }

.book__text {
  background-color: white;
  width: 310px;
  padding: 9px 0 9px 20px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  box-shadow: 7px -4px 50px rgba(0, 0, 0, 0.1); }
  @media (max-width: 991.98px) {
    .book__text {
      /*right: auto;
			left: 0;*/
      width: 100%;
      /*position: relative;
			bottom: 70px;*/ } }
  .book__text p {
    font-size: 12px;
    margin-bottom: 0;
    width: calc(100% - 100px);
    color: #283869; }
  .book__text img {
    width: 50px;
    margin: 0 10px;
    display: block; }

.book__btn {
  display: inline-block;
  font-size: 14px;
  transition: 0.2s ease all;
  text-decoration: none !important;
  padding: 10px 0;
  width: 167px;
  text-align: center; }
  .book__btn-dark {
    background-color: transparent;
    border: 2px solid #060606;
    color: #050505; }
    .book__btn-dark:hover {
      background-color: #050505;
      color: white; }
  .book__btn-warning {
    background-color: #FECC2F;
    border: 2px solid #FECC2F;
    color: #050505; }
    .book__btn-warning:hover {
      background-color: transparent;
      color: #FECC2F; }

.book__link {
  text-decoration: none !important; }
  .book__link-under {
    width: 167px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6; }

.book__col {
  margin-left: 65px; }
  @media (max-width: 991.98px) {
    .book__col {
      margin-left: 0; } }

.book__form {
  background-color: #F5F5F5;
  box-shadow: none;
  max-width: 422px; }
  @media (max-width: 767.98px) {
    .book__form {
      max-width: inherit; } }

.book__label {
  color: rgba(0, 0, 0, 0.5);
  display: block;
  margin-bottom: 15px; }

.book__input {
  border: 1px solid rgba(0, 0, 0, 0.45);
  height: 48px;
  background-color: transparent;
  width: 100%;
  padding: 10px 20px;
  outline: none;
  transition: 0.2s ease all; }
  .book__input:hover, .book__input:focus, .book__input:active {
    border: 1px solid #FECC2F;
    background-color: white; }

.book__title {
  font-size: 36px; }

.book__main-title {
  font-size: 3.3rem;
  margin-bottom: 1.4rem; }
  @media (max-width: 991.98px) {
    .book__main-title {
      font-size: 2.5rem; } }
  @media (max-width: 767.98px) {
    .book__main-title {
      font-size: 2.125rem; } }

.book__form-under {
  display: flex;
  align-items: center; }
  @media (max-width: 767.98px) {
    .book__form-under {
      flex-direction: column; }
      .book__form-under button {
        width: 100%; } }

.book__politic {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
  width: calc(100% - 177px); }
  @media (max-width: 767.98px) {
    .book__politic {
      width: 100%;
      margin-left: 0;
      margin-top: 15px; } }

@media (max-width: 767.98px) {
  .book__order-1 {
    order: 2; } }

@media (max-width: 767.98px) {
  .book__order-2 {
    order: 1; } }

.yellow-border {
  position: relative; }
  .yellow-border .border-container {
    left: 0;
    bottom: 7px;
    position: absolute;
    height: 14px;
    background-color: #FECC2F;
    z-index: -1;
    width: 0; }

.newline {
  animation-name: newline;
  animation-timing-function: linear; }

@keyframes newline {
  from {
    width: 0px; }
  to {
    width: calc(100% + 7px); } }

.book-footer button.data-scroll {
  display: none; }

.book__writers {
  padding: 70px 0;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    .book__writers {
      padding: 30px 0; } }
  .book__writers-scrollbar {
    min-width: 800px;
    width: 100%; }

.book__big-text {
  color: #F8F8F8;
  font-size: 122px;
  font-weight: bold;
  position: absolute;
  top: -66px;
  z-index: -1;
  left: 15px;
  white-space: nowrap;
  visibility: hidden; }
  @media (max-width: 767.98px) {
    .book__big-text {
      font-size: 55px;
      top: -29px; } }

.writer {
  width: 260px;
  position: relative;
  margin-top: 40px; }
  @media (max-width: 991.98px) {
    .writer__container {
      min-width: 260px; } }
  .writer__avatar img {
    max-width: 130px;
    width: 100%;
    margin-left: 60px; }
  .writer__main {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px; }
  .writer__circle {
    width: 165px;
    height: 165px;
    background-color: #FECC2F;
    position: absolute;
    left: 0;
    top: -35px;
    border-radius: 100%;
    z-index: -1; }

.book__col-1 {
  max-width: 230px; }
  @media (max-width: 991.98px) {
    .book__col-1 {
      max-width: 260px; } }
  @media (max-width: 767.98px) {
    .book__col-1 {
      max-width: inherit;
      flex-basis: auto; } }

.book__new {
  background-color: #FECC2F;
  background-image: url("picture/upload/dots.png");
  background-size: 230px;
  background-repeat: repeat-y;
  background-position: top right; }
  .book__new-img {
    width: 100%; }
    @media (max-width: 767.98px) {
      .book__new-img {
        display: block;
        margin: auto;
        max-width: 320px; } }
  @media (max-width: 1279.98px) {
    .book__new-title {
      font-size: 2.7rem; } }
  @media (max-width: 767.98px) {
    .book__new-title {
      font-size: 34px; } }
  .book__new-text {
    margin-bottom: 30px; }
    @media (max-width: 991.98px) {
      .book__new-text {
        margin-bottom: 0; } }
    @media (max-width: 767.98px) {
      .book__new-text {
        margin-bottom: 30px;
        width: 100%;
        flex-basis: auto;
        padding-top: 30px;
        background-color: #FECC2F; } }

.review {
  width: 260px;
  position: relative;
  text-align: center;
  margin: 0 7px; }
  @media (max-width: 991.98px) {
    .review__container {
      min-width: 260px; } }
  .review__avatar img {
    max-width: 114px;
    width: 100%;
    height: 114px;
    object-fit: contain; }
  .review__main {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 15px; }
