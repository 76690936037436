/* Generated by ParaType Ltd (http://www.paratype.com)*/
/* Font PT Serif:  Copyright &copy; 2010 ParaType Ltd. All rights reserved.*/

@font-face {
		font-family: 'PT Sans';
		font-display: fallback;
		src: url('../fonts/PT_Sans-Web-Regular.ttf') format("opentype");
		font-style: normal;
    font-weight: normal;
}

@font-face {
	font-family: 'PT Sans';
	font-display: fallback;
	src: url('../fonts/PT_Sans-Web-Italic.ttf') format("opentype");
	font-style: italic;
	font-weight: normal;
}

@font-face {
	font-family: 'PT Sans';
	font-display: fallback;
	src: url('../fonts/PT_Sans-Web-Bold.ttf') format("opentype");
	font-style: normal;
	font-weight: bold;
}

@font-face {
font-family: 'PT Sans';
font-display: fallback;
src: url('../fonts/PT_Sans-Web-BoldItalic.ttf') format("opentype");
font-style: italic;
font-weight: bold;
}
/*
@font-face {
		font-family: 'PTSans';
		font-display: fallback;
    src: url('../fonts/pts75f_w.eot');
    font-style: normal;
    font-weight: bold;
    src: local('PT Sans'), url('../fonts/PTS75F_W.eot?#iefix') format('embedded-opentype'), url('../fonts/PTS75F_W.woff') format('woff'),url('../fonts/PTS75F_W.ttf') format('truetype'), url('../fonts/PTS75F_W.svg#PTSans-Bold') format('svg');
}*/