.review {
	width: 260px;
	position: relative;
	text-align: center;
	margin: 0 7px;
	&__container {
		@include media-breakpoint-down(md) {
			min-width: 260px;
		}
	}
	&__avatar {
		img {
			max-width: 114px;
			width: 100%;
			height: 114px;
			object-fit: contain;
		}
	}
	&__main {
		font-size: 14px;
		color: rgba(0,0,0,0.5);
		margin-bottom: 15px;
	}
}